import React, { useCallback, useEffect } from 'react';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import { FormControlLabel, Switch, useTheme } from '@mui/material';

import { FormElementSwitchProps } from '../FormTypes';

const FormElementSwitch = ({ name, label, color }: FormElementSwitchProps) => {
  const { control, setValue } = useFormContext();
  const theme = useTheme();

  useEffect(() => {
    setValue(name, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const render: ControllerProps['render'] = useCallback(({ field }) => {
    const value = field.value || false;

    return label ? (
      <FormControlLabel
        control={<Switch checked={value} color={color} onChange={(_, checked) => field.onChange(checked)} />}
        label={label}
        sx={{ color: theme.palette.text.primary }}
      />
    ) : (
      <Switch checked={value} color={color} onChange={(_, checked) => field.onChange(checked)} />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Controller control={control} name={name} render={render} />;
};

export default FormElementSwitch;
