import { GApi } from './types';

const CLIENT_ID = '643034037969-o2qmrgjq9hrj931i1bdkpls7ti1cp7q9.apps.googleusercontent.com';
const API_KEY = 'AIzaSyCopzmL9nXgCqbmNSSS66jCTv10fLbXmZk';

const DISCOVERY_DOCS = ['https://sheets.googleapis.com/$discovery/rest?version=v4'];

const SCOPES = 'https://www.googleapis.com/auth/spreadsheets.readonly';

let gapi: GApi;

export function googleApiAuth() {
  // @ts-ignore
  gapi = window.gapi;
  return new Promise<void>((resolve, reject) => {
    gapi.load('client:auth2', () => {
      gapi.client
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES,
        })
        .then(async () => {
          const signed = gapi.auth2.getAuthInstance().isSignedIn.get();
          if (!signed) {
            gapi.auth2.getAuthInstance().isSignedIn.listen((status: boolean) => {
              if (status) {
                resolve();
              }
            });
            await gapi.auth2.getAuthInstance().signIn();
          } else {
            resolve();
          }
        })
        .catch(reject);
    });
  });
}
