import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

interface DropdownProps<Value> {
  values: string[];
  selected?: Value;
  placeholder: string;
  multiple?: boolean;
  onSelect: (selected: Value) => void;
}
export function DropdownPicker<Value>(props: DropdownProps<Value>) {
  const { selected, placeholder, values, onSelect, multiple } = props;
  return (
    <FormControl sx={{ width: '100%' }}>
      <InputLabel>{placeholder}</InputLabel>
      {/* @ts-ignore */}
      <Select multiple={multiple} value={selected} label={placeholder} onChange={(e) => onSelect(e.target.value)}>
        {values.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
