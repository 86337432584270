import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyles } from './resources/globalStyles';
import { AppProvider } from './redux/AppProvider';
import { useAppDispatch } from './hooks';
import { init } from './redux/thunkActions/appActions';
import AppToast from './components/AppToast';
import AppModalsList from './modals/AppModalsList';
import { Router } from './Router';

function App() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(init());
  }, [dispatch]);
  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  );
}

function AppWrapper() {
  return (
    <AppProvider>
      <App />
      <GlobalStyles />
      <AppToast />
      <AppModalsList />
    </AppProvider>
  );
}

export default AppWrapper;
