import { ZodSchema } from 'zod';
import { CommonFormProps } from '../../form/FormTypes';
import { INotification } from '../../lib/types';

export interface ModalNotificationsProps {
  notification?: Partial<INotification>;
}

export enum FormModalNotificationsValues {
  DISABLED = 'disabled',
  NAME = 'name',
  CHAT_ID = 'chatId',
  THREAD_ID = 'threadId',
  MENTIONS = 'mentions',
  CRON_PATTERN = 'cronPattern',
  TEXT = 'text',
  SECOND_MESSAGE = 'secondMessage',
  SECOND_MESSAGE_DELAY = 'delay',
}

export type BaseModalFormProps<T extends ZodSchema> = CommonFormProps<T>;
