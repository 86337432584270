import React, { useCallback } from 'react';
import { DropdownPicker } from './DropdownPicker';
import { MONTHS_NAMES } from '../resources/constants';
import { Grid } from '@mui/material';
import { YearPicker } from './YearPicker';

interface MonthPickerProps {
  onChangeMonth: (month: number) => void;
  onChangeYear: (month: number) => void;
  selected: number;
  selectedYear: number;
}

export function MonthPicker(props: MonthPickerProps) {
  const { onChangeMonth, onChangeYear, selectedYear, selected } = props;
  const onMonthSelected = useCallback(
    (selected: string | null) => {
      if (selected) {
        onChangeMonth(MONTHS_NAMES.indexOf(selected));
      }
    },
    [onChangeMonth],
  );
  return (
    <React.Fragment>
      <Grid item>
        <DropdownPicker
          onSelect={onMonthSelected}
          placeholder='Месяц'
          values={MONTHS_NAMES}
          selected={MONTHS_NAMES[selected]}
        />
      </Grid>
      <YearPicker selected={selectedYear} onChange={onChangeYear} />
    </React.Fragment>
  );
}
