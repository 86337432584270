import { AppThunk, INotificationBody } from '../../lib/types';
import { Api } from '../../lib/Api';
import { setNotifications } from '../actions';
import AppToast from '../../components/AppToast';

export const getNotificationsAsync = (): AppThunk => async (dispatch) => {
  try {
    const notifications = await Api.call('/tg-bot/reminders');
    dispatch(setNotifications(notifications.data.data));
  } catch (e) {}
};

export const createNotification =
  (data: INotificationBody): AppThunk =>
  async (dispatch) => {
    try {
      const response = await Api.call('/tg-bot/reminders', {
        method: 'post',
        data,
      });
      if (response.status === 201) {
        dispatch(getNotificationsAsync());
      } else {
        AppToast.show(response.data.message || 'Ошибка');
      }
    } catch (e) {
      AppToast.show((e as any).message || 'Ошибка');
    }
  };

export const updateNotification =
  (id: string, data: INotificationBody): AppThunk =>
  async (dispatch) => {
    try {
      const response = await Api.call(`/tg-bot/reminders/${id}`, {
        method: 'patch',
        data,
      });
      if (response.status === 200) {
        dispatch(getNotificationsAsync());
      } else {
        AppToast.show(response.data.message || 'Ошибка');
      }
    } catch (e) {
      AppToast.show((e as any).message || 'Ошибка');
    }
  };

export const deleteNotification =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      const response = await Api.call(`/tg-bot/reminders/${id}`, {
        method: 'delete',
      });
      if (response.status === 200) {
        dispatch(getNotificationsAsync());
      } else {
        AppToast.show(response.data.message || 'Ошибка');
      }
    } catch (e) {
      AppToast.show((e as any).message || 'Ошибка');
    }
  };
