import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../redux/hooks/selectors';
import { MonthPicker } from '../components/MonthPicker';
import { requestMonthDataAsync } from '../redux/thunkActions/dataActions';
import { DEFAULT_MONTH_PICKER_VALUES } from '../resources/constants';
import { Grid, Typography } from '@mui/material';
import { Loader } from '../components/Loader';
import { Input } from '../components/Input';
import CollapsibleTable, { TableConfig } from '../components/CollapsibleTable';
import { recordToArray } from '../lib/utils';
import { useAppDispatch } from '../hooks';

export function Overtime() {
  const [year, setYear] = useState(DEFAULT_MONTH_PICKER_VALUES.year);
  const [month, setMonth] = useState(DEFAULT_MONTH_PICKER_VALUES.month);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(requestMonthDataAsync(month, year));
  }, [dispatch, month, year]);

  const data = useAppSelector((state) => state.data.personData);
  const isLoading = useAppSelector((state) => state.data.isLoading);
  const [hoursInMonth, setHoursInMonth] = useState(176);
  const onChangeHours = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => setHoursInMonth(Number(event.target.value)),
    [],
  );

  const tableConfig: TableConfig = useMemo(
    () => ({
      head: ['ФИО', 'Часы', 'Разница'].map((label) => ({ label })),
      rows: data.map((item) => ({
        row: [item.title, item.value.totalTime, item.value.totalTime - hoursInMonth],
        subItems: {
          head: [{ label: 'Проект' }, { label: 'Часы', width: 140 }],
          rows: recordToArray(item.value.detail as any).map((subItem) => ({
            row: [subItem.title as string, (subItem.value as any).totalTime as number],
          })),
        },
      })),
    }),
    [data, hoursInMonth],
  );
  return (
    <Grid container direction={'column'} spacing={4} padding={2}>
      <Loader isLoading={isLoading} />
      <Grid item>
        <Typography variant={'h6'}>Сотрудники</Typography>
      </Grid>
      <Grid container item direction={'row'} spacing={2}>
        <Input label='Часов в месяце' value={hoursInMonth} onChange={onChangeHours} />
        <MonthPicker onChangeMonth={setMonth} selected={month} onChangeYear={setYear} selectedYear={year} />
      </Grid>
      <Grid container item>
        {data.length > 0 && <CollapsibleTable isSmall config={tableConfig} />}
      </Grid>
    </Grid>
  );
}
