import { z } from 'zod';
import { maxLength, minLength } from '../resources/constants';

export const stringSchema = z.string({ required_error: 'Обязательное поле' });

export const getStringSchemaWithLimits = (minLength: number, maxLength: number) =>
  stringSchema
    .min(minLength, `Должно быть не менее ${minLength} символов`)
    .max(maxLength, `Должно быть не более ${maxLength} символов`);

export const looseOptional = <T extends z.ZodTypeAny>(schema: T) =>
  z.preprocess(
    (value: unknown) => (value === null || (typeof value === 'string' && value === '') ? undefined : value),
    schema.optional(),
  );

export const stringWithCommonLimitSchema = getStringSchemaWithLimits(minLength.common, maxLength.common);
export const nonEmptyStringsArray = stringWithCommonLimitSchema.array().nonempty()
export const stringWithDesriptionLimitSchema = getStringSchemaWithLimits(minLength.common, maxLength.description);
export const stringWithLinkTitleLimitSchema = getStringSchemaWithLimits(minLength.common, maxLength.linkUrl);

export const passwordSchema = getStringSchemaWithLimits(minLength.password, maxLength.password);

export const emailSchema = stringSchema.email({
  message: 'Неверный адрес электронной почты',
});

export const dateSchema = stringSchema.datetime({
  message: 'Указана некорректная дата',
});

export const checkBoxSchema = z.boolean();

export const urlSchema = stringSchema.url({
  message: 'Указанна некорректная ссылка',
});
