import { reset, setInitialized } from '../actions';
import { AppEvents, EventDispatcher } from '../../lib/events/eventDispatcher';
import { AppThunk } from '../../lib/types';
import { getUserInfoAsync } from './userActions';

export const init = (): AppThunk => (dispatch) => {
  dispatch(getUserInfoAsync());
  dispatch(setInitialized());
};

export const logout = (): AppThunk => async (dispatch) => {
  localStorage.clear();
  dispatch(reset());
  EventDispatcher.instance.dispatchEvent(AppEvents.LOGOUT);
};
