import React, { useForm, FormProvider, FieldValues } from 'react-hook-form';

import { FormForContextProps, FormProps, FormWithContextProps } from './FormTypes';
import { Loader } from '../../components/Loader';

// NOTE
// https://react-hook-form.com/advanced-usage#SmartFormComponent

const FormWithContext = <TFormValues extends FieldValues>({
  onSubmit,
  children,
  isFlexible,
  defaultValues,
  ...restProps
}: FormWithContextProps<TFormValues>) => {
  const methods = useForm({ defaultValues });

  return (
    // @ts-ignore
    <FormProvider {...methods}>
      <form {...restProps} onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
};

const FormForContext = ({ onSubmit, children, contextMethods, isFlexible, ...restProps }: FormForContextProps) => {
  const methods = contextMethods;

  return (
    // @ts-ignore
    <FormProvider {...methods}>
      <form {...restProps} onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
};

const Form = <TFieldValues extends FieldValues>({ children, isLoading, ...restProps }: FormProps<TFieldValues>) => {
  const markup = (
    <>
      {isLoading && <Loader isLoading />}
      {children}
    </>
  );

  if ('contextMethods' in restProps && restProps.contextMethods) {
    // @ts-ignore
    return <FormForContext {...restProps}>{markup}</FormForContext>;
  }

  return <FormWithContext {...restProps}>{markup}</FormWithContext>;
};

export default Form;
