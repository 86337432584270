export const colors = {
  background: '#fff',
  white: '#ffffff',
  red: '#FF7271',
  blue: '#7358FF',
  orange: '#FF754C',
  black: '#000000',
  text: '#1B1B1B',
  base: '#1B1B1B',
  placeholder: 'rgba(27, 27, 27, 0.2)',
};

export default colors;
