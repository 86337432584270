import React, { useEffect, useState } from 'react';
import { Input } from '../../components/Input';
import { useAppDispatch, useAwaitCallback } from '../../hooks';
import { loginAsync } from '../../redux/thunkActions/userActions';
import { Grid, Card, Button } from '@mui/material';
import { Loader } from '../../components/Loader';
import { useUser } from '../../redux/selectors/useUser';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../resources/routes';

export function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useUser();

  const [onSubmit, isLoading] = useAwaitCallback(async () => {
    await dispatch(loginAsync(email, password));
  }, [dispatch, password, email]);

  useEffect(() => {
    if (user) {
      if (window.history.length > 1) {
        window.history.back();
      } else {
        navigate(routes.index.path, { replace: true });
      }
    }
  }, [user, navigate]);

  return (
    <Grid container alignItems={'center'} justifyContent={'center'}>
      <Card>
        <Loader isLoading={isLoading} />
        <Grid padding={'16px'} container spacing={2} direction={'column'}>
          <Input label='Почта' onChangeText={setEmail} />

          <Input label='Пароль' onChangeText={setPassword} type={'password'} />

          <Grid item xs={2}>
            <Button disabled={isLoading} type={'submit'} variant={'contained'} onClick={onSubmit}>
              Войти
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}
