import { createGlobalStyle } from 'styled-components';
import colors from './colors';

export const GlobalStyles = createGlobalStyle`
  body, html {
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }

  #root {
    display: flex;
    width: 100%;
    height: 100%;
  }

  img {
    -webkit-touch-callout: none;
  }

  * {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
  }

  a:visited {
    color: rgb(0, 0, 238)
  }

  ::-webkit-input-placeholder {
    color: ${colors.placeholder};
  }

  :-ms-input-placeholder {
    color: ${colors.placeholder};
  }

  ::placeholder {
    color: ${colors.placeholder};
  }
`;
