import { createLogger } from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { reducers } from './reducers';
import { isDebug } from '../resources/constants';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist/es/constants';

const loggerMiddleware = createLogger({
  predicate: () => Boolean(isDebug),
  collapsed: true,
  timestamp: true,
});
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['user'],
};

const persistedReducer = persistReducer(persistConfig, combineReducers(reducers));
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(loggerMiddleware),
});
export const persistor = persistStore(store);
