import { AppThunk } from '../../lib/types';
import { Api } from '../../lib/Api';
import { setAuth, setUserInfo } from '../actions';
import AppToast from '../../components/AppToast';
import LocalStorage from '../../lib/LocalStorage';

export const getUserInfoAsync = (): AppThunk => async (dispatch) => {
  try {
    const user = await Api.call('/users');
    dispatch(setUserInfo(user.data));
  } catch (e) {}
};

export const loginAsync =
  (email: string, password: string): AppThunk =>
  async (dispatch) => {
    try {
      const response = await Api.call('/auth/login', {
        method: 'post',
        data: {
          email,
          password,
        },
      });
      if (response.status === 200) {
        dispatch(setAuth(response.data));
        dispatch(getUserInfoAsync());
      } else {
        AppToast.show(response.data.message || 'Ошибка');
      }
    } catch (e) {
      AppToast.show((e as any).message || 'Ошибка');
    }
  };

export const logoutAsync = (): AppThunk => () => {
  LocalStorage.clear();
  window.location.reload();
};
