import { createReducer } from '@reduxjs/toolkit';
import { reset, setIsLoading, setLastSyncDate, setPersonData, setProjectData, setDataToSync } from '../actions';
import { DataState } from '../../lib/types';

const initialState: DataState = {
  personData: [],
  projectData: [],
  isLoading: true,
  dataToSync: undefined,
  lastSyncDate: '',
};

export const dataReducer = createReducer<DataState>(initialState, (builder) => {
  builder
    .addCase(reset, () => initialState)
    .addCase(setPersonData, (state, action) => ({ ...state, personData: action.payload }))
    .addCase(setProjectData, (state, action) => ({ ...state, projectData: action.payload }))
    .addCase(setIsLoading, (state, action) => ({ ...state, isLoading: action.payload }))
    .addCase(setDataToSync, (state, action) => ({ ...state, dataToSync: action.payload }))
    .addCase(setLastSyncDate, (state, action) => ({ ...state, lastSyncDate: action.payload }));
});
