import React, { useEffect } from 'react';
import { Routes, useNavigate } from 'react-router-dom';
import { routes } from './resources/routes';
import { useUser } from './redux/selectors/useUser';
import { Route } from 'react-router';
import { NotFoundPage } from './pages/Errors/NotFoundPage';
import { Page } from './components/Page';

export function Router() {
  const navigate = useNavigate();
  const user = useUser();

  useEffect(() => {
    if (!user) navigate(routes.login.path);
  }, [user, navigate]);

  const pages = Object.entries(routes).map(([_, config]) => {
    const render = () => {
      const Component = config.component;

      const isLoginPage = config.path === routes.login.path;

      return (
        <>
          {isLoginPage ? (
            <Component />
          ) : (
            <Page>
              <Component />
            </Page>
          )}
        </>
      );
    };

    return <Route key={config.path} path={config.path} element={render()} />;
  });

  return (
    <Routes>
      {pages.map((route, i) => (
        <React.Fragment key={route.key! + i}>{route}</React.Fragment>
      ))}

      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  );
}
