import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { TableContainerProps } from '@mui/material/TableContainer/TableContainer';

export function TableActionCell({ children }: React.PropsWithChildren) {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '10px',
      }}>
      {children}
    </Box>
  );
}

function Row(props: { collapsible: boolean; row: RowConfig; isSmall?: boolean }) {
  const { row, collapsible, isSmall } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        {collapsible && (
          <TableCell size={isSmall ? 'small' : 'medium'}>
            <IconButton
              disabled={!row.subItems?.rows?.length}
              aria-label='expand row'
              size='small'
              onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        {row.row.map((item, index) => (
          <TableCell size={isSmall ? 'small' : 'medium'} key={index} component='th' scope='row'>
            {item}
          </TableCell>
        ))}
      </TableRow>
      {row.subItems ? (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <CollapsibleTable isSmall config={row.subItems} isCollapsible={false} />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </React.Fragment>
  );
}

interface RowConfig {
  row: React.ReactNode[];
  subItems?: TableConfig;
}

interface TableHeadConfig {
  label: string;
  align?: TableCellProps['align'];
  width?: number;
}

export interface TableConfig {
  head: TableHeadConfig[];
  rows: RowConfig[];
}

interface Props {
  config: TableConfig;
  isCollapsible?: boolean;
  isSmall?: boolean;
  component?: TableContainerProps['component'];
}

export default function CollapsibleTable(props: Props) {
  const { config, isCollapsible = true, isSmall, component } = props;
  const { head, rows } = config;
  const tableContainerProps: TableContainerProps = {};
  if (isCollapsible) {
    tableContainerProps.component = Paper;
  }
  if (component) {
    tableContainerProps.component = component;
  }

  return (
    <TableContainer {...tableContainerProps}>
      <Table aria-label='collapsible table'>
        <TableHead>
          <TableRow>
            {isCollapsible ? <TableCell sx={{ width: 66 }} size={isSmall ? 'small' : 'medium'} /> : null}
            {head.map((i) => (
              <TableCell
                align={i.align || 'left'}
                sx={{ width: i.width }}
                key={i.label}
                size={isSmall ? 'small' : 'medium'}>
                {i.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <Row key={index} row={row} isSmall={isSmall} collapsible={isCollapsible} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
