import React from 'react';
import {MonthKPI} from './MonthKPI';
import {useAppSelector} from '../../redux/hooks/selectors';
import {Role} from '../../lib/types';
import {ErrorPage} from '../Errors/Error';

export function Main() {
  const user = useAppSelector((state) => state.user.user);
  if (!user) {
    return <ErrorPage status={500} text={'Произошла неизвестная ошибка'}/>;
  }
  if (user.isAdmin) {
    return <MonthKPI/>;

  }
  switch (user.role) {
    case Role.MANAGER:
    case Role.OWNER:
    case Role.CFO:
      return <MonthKPI/>;
    case Role.BACK:
    case Role.FRONT:
    case Role.QA:
    case Role.DEVOPS:
    case Role.DESIGN:
      return <div/>;
  }
  return <ErrorPage status={500} text={'Произошла неизвестная ошибка'}/>;
}
