import { createReducer } from '@reduxjs/toolkit';
import { setAuth, setUserInfo } from '../actions';
import { UserState } from '../../lib/types';
import { Api } from '../../lib/Api';

const initialState: UserState = {
  user: undefined,
  token: '',
};

export const userReducer = createReducer<UserState>(initialState, (builder) => {
  builder
    .addCase('persist/REHYDRATE', (state, action: any) => {
      if (action.payload?.user) {
        Api.setToken(action.payload.user.token);
      }
      return action.payload?.user || initialState;
    })
    .addCase(setAuth, (state, action) => {
      Api.setToken(action.payload.token);
      return { ...state, ...action.payload };
    })
    .addCase(setUserInfo, (state, action) => ({ ...state, user: action.payload }));
});
