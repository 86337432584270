import { createReducer } from '@reduxjs/toolkit';
import { setNotifications } from '../actions';
import { NotificationsState } from '../../lib/types';

const initialState: NotificationsState = {
  notifications: undefined,
};

export const notificationsReducer = createReducer<NotificationsState>(initialState, (builder) => {
  builder.addCase(setNotifications, (state, action) => ({ ...state, notifications: action.payload }));
});
