import React, { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import { FocusEvent, useCallback } from 'react';

import { FormElementInputProps } from './FormTypes';
import { Input } from '../components/Input';

const FormElementInput = ({
  name,
  hideHelperText = false,
  description: propsDescription,
  component: _,
  ...restProps
}: FormElementInputProps) => {
  const { control } = useFormContext();

  const render: ControllerProps['render'] = useCallback(
    ({ field: { ref, ...field }, fieldState }) => {
      const value = field.value || '';

      const isError = Boolean(fieldState.error?.message);
      const validateDescription = fieldState.error?.message;

      const description = !hideHelperText ? validateDescription || propsDescription : undefined;

      const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
        const cleanedValue = event.target.value.trim();
        field.onChange(cleanedValue);
        field.onBlur();
      };

      return (
        <Input
          {...restProps}
          {...field}
          fullWidth
          error={isError}
          helperText={description}
          inputRef={ref}
          value={value}
          onBlur={handleOnBlur}
        />
      );
    },
    [hideHelperText, propsDescription, restProps],
  );

  return <Controller control={control} name={name} render={render} />;
};

export default FormElementInput;
