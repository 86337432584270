import React from 'react';
import { Alert, Snackbar } from '@mui/material';

interface AppToastProps {}
interface AppToastState {
  text: string;
}
export default class AppToast extends React.Component {
  private static instance?: AppToast;

  state: AppToastState = {
    text: '',
  };

  constructor(props: AppToastProps) {
    super(props);
    AppToast.instance = this;
  }

  componentWillUnmount() {
    AppToast.instance = undefined;
  }

  static show(text: string) {
    AppToast.instance?.show(text);
  }

  show(text: string) {
    this.setState({ text });
  }

  close = () => {
    this.setState({ text: '' });
  };

  render() {
    const { text } = this.state;
    return (
      <Snackbar
        autoHideDuration={6000}
        open={Boolean(text)}
        onClose={this.close}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={this.close} severity='error' sx={{ width: '100%' }}>
          {text}
        </Alert>
      </Snackbar>
    );
  }
}
