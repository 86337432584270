import React from 'react';
import { Grid, Link, Typography } from '@mui/material';
import { routes } from '../../resources/routes';

interface ErrorPageProps {
  text?: string;
  status?: number;
}

export function ErrorPage(props: ErrorPageProps) {
  const { status = 500, text = 'Произошла ошибка' } = props;
  return (
    <Grid container justifyContent={'center'} direction={'column'} alignItems={'center'} spacing={8}>
      <Grid item>
        <Typography variant={'h1'} textAlign={'center'}>
          {status}
        </Typography>
        <Typography textAlign={'center'} variant={'h2'}>
          {text}
        </Typography>
      </Grid>
      <Grid item>
        <Link href={routes.index.path}>На главную</Link>
      </Grid>
    </Grid>
  );
}
