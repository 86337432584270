import moment from 'moment/moment';

const isDevelop = process.env.NODE_ENV === 'development';
export const isDebug = localStorage.getItem('isDebug') || isDevelop;

export const DEFAULT_MONTH_PICKER_VALUES = {
  year: moment().year(),
  month: moment().month(),
};
export const MONTHS_NAMES = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

export const DATE_FORMAT = 'YYYY-MM-DD';

export const isCantata = window.location.href.includes('reports-cantata');

enum Stands {
  vvdev = 'vvdev',
}

type AppConfig = {
  SPREADSHEET_ID: string;
  SALARY_SHEET_ID: string;
  title: string;
  showMoney?: boolean;
};

export const config: Record<Stands, AppConfig> = {
  vvdev: {
    SPREADSHEET_ID: '1cPT2sFDy9wVmxI4x18UOZiRkUHgdDM1i0_uqbU-tTyk',
    SALARY_SHEET_ID: isDevelop ? '1mkwV2qHiTp2XCAlpXj17BkDTnpNVo5r_aG8AYHmDBtQ' : '1GHBWGIooJyM57KyYDhJsr0p1VdiWNDcU8HojX35K3Jo',
    title: 'vvdev',
    showMoney: true,
  },
};

const activeStand: Stands = Stands.vvdev;

export const { SPREADSHEET_ID, SALARY_SHEET_ID, title, showMoney } = config[activeStand];

export const minLength = {
  password: 8,
  common: 1,
};

export const maxLength = {
  password: 16,
  common: 64,
  description: 300,
  linkUrl: 20,
};
