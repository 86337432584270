import React, { useMemo } from 'react';
import { Box } from '@mui/material';

import FormElementSwitch from '../FormElementSwitch/FormElementSwitch';
import FormElementSelect from '../FormElementSelect/FormElementSelect';
import FormElementInput from '../FormElementInput';
import FormElementCheckbox from '../FormElementCheckbox/FormElementCheckbox';
import { FormElementProps } from '../FormElement/FormElementTypes';

const isMarkedRequiredFields = false;

const FormElement = ({ name, className, ...restProps }: FormElementProps) => {
  if (isMarkedRequiredFields) {
    if ('label' in restProps && restProps.label) {
      restProps.label = restProps.label + '*';
    }
  }

  const Component = useMemo(() => {
    if (restProps.component === 'select') {
      return <FormElementSelect name={name} {...restProps} />;
    }

    if (restProps.component === 'checkbox') {
      return <FormElementCheckbox name={name} {...restProps} />;
    }

    if (restProps.component === 'switch') {
      return <FormElementSwitch name={name} {...restProps} />;
    }

    return <FormElementInput name={name} {...restProps} />;
  }, [name, restProps]);

  return <Box className={className}>{Component}</Box>;
};

export default FormElement;
