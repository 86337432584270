import React, { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import { MouseEvent, useCallback, useMemo } from 'react';
import { FormControl, Select, InputLabel, MenuItem, FormHelperText, Chip, Typography, Grid } from '@mui/material';

import { FormElementSelectProps } from '../FormTypes';
import useControlSelectValue from '../../hooks/useControlSelectValue';

const FormElementSelect = ({
  name,
  label,
  style,
  values,
  disabled,
  extraItems,
  disableAutofill = false,
  emptyOptionMessage,
  description: propsDescription,
  size = 'medium',
  ...props
}: FormElementSelectProps) => {
  const id = `select-${name}`;

  const { control, setValue, watch } = useFormContext();

  const value: string | string[] = watch(name);

  const items: FormElementSelectProps['values'] = useMemo(() => {
    const res = [...values];
    if (extraItems) {
      for (let i = 0; i < extraItems.length; i++) {
        if (!values.find((item) => item.value === extraItems[i].value)) {
          res.unshift(extraItems[i]);
        }
      }
    }
    return res;
  }, [extraItems, values]);
  const handleChangeValue = useCallback(
    (newValue: string | string[]) => {
      setValue(name, newValue);
    },
    [name, setValue],
  );

  const handleDeleteChip = useCallback(
    (index: number) => (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.preventDefault();

      const newValue = Array.isArray(value) ? [...value] : [value];
      newValue.splice(index, 1);
      handleChangeValue(newValue);
    },
    [handleChangeValue, value],
  );

  const handleMouseDownChip = useCallback((e: MouseEvent<SVGElement>) => {
    e.stopPropagation();
    e.preventDefault();
  }, []);

  const renderChildren = useCallback(() => {
    if (!items.length) {
      return (
        <MenuItem disabled value={''}>
          {emptyOptionMessage || 'Список опций пуст'}
        </MenuItem>
      );
    }

    return items.map((a) => (
      <MenuItem disabled={a.disabled} key={a.value} value={a.value}>
        <Grid direction='column'>
          <Typography variant='body1'>{a.label}</Typography>

          {a.suggestion && (
            <Typography color='text.secondary' sx={{ ml: 2 }} variant='body2'>
              ({a.suggestion})
            </Typography>
          )}
        </Grid>
      </MenuItem>
    ));
  }, [emptyOptionMessage, items]);

  const renderChips = useCallback(() => {
    if (!value) return null;
    if (typeof value === 'string') return null;
    if (props.isMultiple && props.renderSelected !== 'chips') return null;

    return (
      <Grid direction='column'>
        {value.map((a, i) => (
          <Chip key={a} label={values.find((item) => item.value === a)?.label} onDelete={handleDeleteChip(i)} />
        ))}
      </Grid>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleDeleteChip, handleMouseDownChip, props, value, values]);

  const renderValue = useCallback(
    (value: string[] | string) => {
      if (typeof value === 'string') return value || '';
      if (!value.length) return '';

      const result = value.reduce((prev, next) => {
        const result = values.find((item) => item.value === next);

        if (result?.placeholderName) {
          return prev ? prev + ', ' + result.placeholderName : result.placeholderName;
        }

        if (props.isMultiple && props.renderSelected === 'placeholder') {
          if (result?.label) {
            return prev ? prev + ', ' + result.label : result.label;
          }
        }

        return prev;
      }, '');

      return result;
    },
    [props, values],
  );

  const render: ControllerProps['render'] = useCallback(
    ({ field, fieldState }) => {
      const selectValue = field.value || (props.isMultiple ? [] : '');

      const isError = Boolean(fieldState.error?.message);
      const validateDescription = fieldState.error?.message;

      const description = validateDescription || propsDescription;

      return (
        // TODO: Нелогичность размеров, пофиксить, добавив новый размер в TS
        <FormControl fullWidth size={size} style={style}>
          <InputLabel error={isError} id={id}>
            {label}
          </InputLabel>

          <Select
            {...field}
            disabled={!isError && disabled}
            error={isError}
            label={label}
            labelId={id}
            multiple={props.isMultiple}
            renderValue={props.isMultiple ? renderValue : undefined}
            value={selectValue}>
            {renderChildren()}
          </Select>

          {!!description && (
            <FormHelperText error={isError} id={id}>
              {description}
            </FormHelperText>
          )}
        </FormControl>
      );
    },

    [props.isMultiple, propsDescription, size, style, id, label, disabled, renderValue, renderChildren],
  );

  useControlSelectValue(Boolean(disableAutofill), values, value, handleChangeValue, props.isMultiple);

  return (
    <>
      <Controller control={control} name={name} render={render} />
      {renderChips()}
    </>
  );
};

export default FormElementSelect;
