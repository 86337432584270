import React, { useEffect, useMemo, useState } from 'react';
import { requestMonthDataAsync } from '../../redux/thunkActions/dataActions';
import { PTable } from '../../components/PTable';
import { useAppSelector } from '../../redux/hooks/selectors';
import { MonthPicker } from '../../components/MonthPicker';
import { Input } from '../../components/Input';
import { Button, Grid } from '@mui/material';
import { Loader } from '../../components/Loader';
import { DEFAULT_MONTH_PICKER_VALUES } from '../../resources/constants';
import { useAppDispatch } from '../../hooks';

const defaultState = {
  ...DEFAULT_MONTH_PICKER_VALUES,
  project: '',
};

export function MonthKPI() {
  const user = useAppSelector((state) => state.user.user)!;
  const [year, setYear] = useState(defaultState.year);
  const [month, setMonth] = useState(defaultState.month);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(requestMonthDataAsync(month, year));
  }, [dispatch, month, year]);

  const projectData = useAppSelector((state) => state.data.projectData);
  const isLoading = useAppSelector((state) => state.data.isLoading);
  const [projectFilter, setProjectFilter] = useState(defaultState.project);

  const resetState = () => {
    setProjectFilter(defaultState.project);
    setYear(defaultState.year);
    setMonth(defaultState.month);
  };
  const data = useMemo(
    () => projectData.filter((p) => p.title.indexOf(projectFilter.toLowerCase().trim()) !== -1),
    [projectData, projectFilter],
  );
  return (
    <Grid container padding={2} direction={'column'} spacing={4}>
      <Loader isLoading={isLoading} />
      <Grid container item spacing={2} direction={'row'} alignItems={'center'}>
        <Input xs={6} type={'search'} label={'Проект'} onChangeText={setProjectFilter} value={projectFilter} />
        <MonthPicker onChangeMonth={setMonth} selected={month} onChangeYear={setYear} selectedYear={year} />
        <Grid item>
          <Button variant='contained' color={'error'} onClick={resetState}>
            Сброс
          </Button>
        </Grid>
      </Grid>
      <Grid item>{data.length > 0 && <PTable isAdmin={user.isAdmin} data={data} role={user.role} />}</Grid>
    </Grid>
  );
}
