import React from 'react';
import NotificationsModal from './notifications/NotificationsModal';

const AppModalsList = () => {
  return (
    <>
      <NotificationsModal />
    </>
  );
};

export default AppModalsList;
