import { GApi } from './types';
import { SPREADSHEET_ID } from '../../../resources/constants';

const LIST_NAME = 'План';

export async function getColumns(
  start: string,
  end = start,
  spreadsheetId = SPREADSHEET_ID,
  list = LIST_NAME,
): Promise<string[][]> {
  // @ts-ignore
  const gapi: GApi = window.gapi;
  const sheets = gapi.client.sheets;
  const res = await sheets.spreadsheets.values.get({
    spreadsheetId,
    range: `${list}!${start}1:${end}`,
  });
  return res.result.values;
}

export function tableToJSON(data: string[][]): Record<string, string>[] {
  const res: Record<string, string>[] = [];
  const head = data[0].map((cell) => cell.toLowerCase());
  for (let i = 1; i < data.length; i++) {
    if (data[i][0] && data[i][0].toLowerCase() !== 'итого') {
      const row: Record<string, string> = {};
      res.push(row);
      for (let j = 0; j < head.length; j++) {
        row[head[j]] = data[i][j];
      }
    }
  }
  return res;
}
