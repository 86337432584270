import { Main } from '../pages/Main';
import { Overtime } from '../pages/Overtime';
import { AirtableSync } from '../pages/AirtableSync';
import { SalaryPage } from '../pages/SalaryPage';
import { ProjectKPI } from '../pages/ProjectKPI';
import { LoginPage } from '../pages/LoginPage';
import { Notifications } from '../pages/Notifications';

interface Routes {
  [key: string]: {
    path: string;
    admin: boolean;
    component: () => JSX.Element;
    label: string;
    private: boolean;
  };
}

export const routes = {
  index: {
    path: '/',
    admin: false,
    private: false,
    component: Main,
    label: 'Месячный отчет',
  },
  projects: {
    admin: false,
    private: false,
    path: '/projects',
    component: ProjectKPI,
    label: 'Экономика проектов',
  },
  overtime: {
    admin: false,
    private: false,
    path: '/overtime',
    component: Overtime,
    label: 'Сотрудники',
  },
  airtable: {
    admin: true,
    private: false,
    path: '/airtable',
    component: AirtableSync,
    label: 'Выгрузка часов',
  },
  salary: {
    admin: true,
    private: false,
    path: '/salary',
    component: SalaryPage,
    label: 'Выгрузка ЗП',
  },
  notifications: {
    admin: false,
    private: false,
    path: '/notifications',
    component: Notifications,
    label: 'Уведомления',
  },
  login: {
    admin: false,
    private: true,
    path: '/login',
    component: LoginPage,
    label: 'Войти',
  },
} as const satisfies Routes;
