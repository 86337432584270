import { appReducer } from './app';
import { dataReducer } from './data';
import { userReducer } from './user';
import { notificationsReducer } from './notifications';

export const reducers = {
  app: appReducer,
  data: dataReducer,
  user: userReducer,
  notifications: notificationsReducer,
};
