import React, { useCallback } from 'react';
import { DropdownPicker } from './DropdownPicker';
import { Grid } from '@mui/material';

const FIRST_YEAR = 2022;
const YEARS = new Array(new Date().getFullYear() + 1 - FIRST_YEAR)
  .fill(1)
  .map((_, index) => String(index + FIRST_YEAR));

interface YearPickerProps {
  onChange: (year: number) => void;
  selected: number;
}

export function YearPicker(props: YearPickerProps) {
  const { onChange, selected } = props;
  const onYearSelected = useCallback(
    (selected: string | null) => {
      if (selected) {
        onChange(Number(selected));
      }
    },
    [onChange],
  );
  return (
    <React.Fragment>
      <Grid item>
        <DropdownPicker onSelect={onYearSelected} placeholder='Год' values={YEARS} selected={selected.toString()} />
      </Grid>
    </React.Fragment>
  );
}
