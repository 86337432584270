import { AirtableItem, ApiStatisticsResponse, AppThunk, CompanyUser } from '../../lib/types';
import { Api } from '../../lib/Api';
import { getMonthDatesRange, recordToArray } from '../../lib/utils';
import { setIsLoading, setLastSyncDate, setPersonData, setProjectData, setDataToSync } from '../actions';
import { googleApiAuth } from '../../lib/utils/gsheets';
import { DATE_FORMAT, MONTHS_NAMES, SALARY_SHEET_ID } from '../../resources/constants';
import moment from 'moment';
import { processAirtableResponse } from '../../actions/onLoadData';
import AppToast from '../../components/AppToast';
import { getColumns, tableToJSON } from '../../lib/utils/gsheets/tableActions';

export const requestMonthDataAsync =
  (month: number, year: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const [DATE_FROM, DATE_TO] = getMonthDatesRange(month, year);
      const formula = `AND(IS_AFTER({date}, "${DATE_FROM}"), IS_BEFORE({date}, "${DATE_TO}"))`;

      const records = [];
      let offset = '';
      do {
        const response = await Api.call.get('/statistics', {
          params: {
            filterByFormula: formula,
            offset: offset || undefined,
          },
        });
        const json: { offset: string; records: AirtableItem[] } = response.data;
        offset = json.offset;
        records.push(...json.records);
      } while (offset);

      const byFullName = processAirtableResponse({ records }, '_person', '_project');
      const byProject = processAirtableResponse({ records }, '_project', '_person', false);
      // @ts-ignore
      dispatch(setPersonData(recordToArray(byFullName)));
      dispatch(setProjectData(recordToArray(byProject).sort((a, b) => (a.title > b.title ? 1 : -1))));
    } catch (e) {
      console.error(e);
      alert('Ошибка при загрузке данных');
    }
    dispatch(setIsLoading(false));
  };

export const requestProjectDataAsync =
  (projects: string[], year: number): AppThunk<Promise<any>> =>
  async (): Promise<any> => {
    try {
      const records = [];
      for (const project of projects) {
        const formula = `AND(FIND("${project}", {_project}), {year}="${year}")`;
        let offset = '';
        do {
          const response = await Api.call.get<ApiStatisticsResponse>('/statistics', {
            params: {
              filterByFormula: formula,
              offset: offset || undefined,
            },
          });
          const json: { offset: string; records: AirtableItem[] } = response.data;
          offset = json.offset;
          records.push(...json.records);
        } while (offset);
      }
      const data = processAirtableResponse({ records }, 'month', '_person', false);
      return recordToArray(data)
        .sort((a, b) => Number(a.title) - Number(b.title))
        .map((item) => ({ ...item, title: MONTHS_NAMES[Number(item.title) - 1] }));
    } catch (e) {
      console.error(e);
      alert('Ошибка при загрузке данных');
    }
    return [];
  };

export const requestLastSyncDateAsync = (): AppThunk => async (dispatch) => {
  const response = await Api.call.get('/statistics?sort[0][field]=date&sort[0][direction]=desc&limit=1');
  const date = response.data.records[0].fields.date;
  dispatch(setLastSyncDate(date));
};

export const syncDataAsync =
  (lastCheckedDate: Date): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    try {
      dispatch(setIsLoading(true));
      try {
        const response = await Api.call.get('/schedule/working_time', {
          params: {
            from: moment(getState().data.lastSyncDate).add(1, 'day').format(DATE_FORMAT),
            to: moment(lastCheckedDate).format(DATE_FORMAT),
          },
        });
        dispatch(setDataToSync(response.data));
        return response.data;
      } catch (e) {
        const message = (e as any)?.response?.data?.message || 'Ошибка при загрузке данных';
        AppToast.show(message);
      }
      dispatch(setIsLoading(false));
    } catch (e) {
      console.error(e);
      alert('Ошибка при загрузке данных');
    }
    dispatch(setIsLoading(false));
  };

export type SalaryRow = {
  fullName: string;
  date: Date;
  sum: number;
};

export const syncSalaryDataAsync =
  (year: number): AppThunk<Promise<SalaryRow[]>> =>
  async () => {
    try {
      await googleApiAuth();
      const data = await getColumns('a', 'v900', SALARY_SHEET_ID, year.toString());
      // @ts-ignore
      const jsons = tableToJSON(data);
      const rows: SalaryRow[] = jsons.map((json) => ({
        fullName: (json['фио'] || '').replace(/\(.*\)/g, ''),
        sum: Number((json['итог'] || '0').replace(/[^\d]*/g, '')),
        date: new Date(
          year,
          MONTHS_NAMES.findIndex((item) => item.toLowerCase() === json['месяц'].toLowerCase()),
          15,
        ),
      }));
      return rows;
    } catch (e) {
      console.error(e);
      alert('Ошибка при загрузке данных');
    }
    return [];
  };

export const sendSalaryToAirtable =
  (data: SalaryRow[], year: number): AppThunk =>
  async () => {
    try {
      await Api.call.post('/schedule/salary', {
        year,
        data: data.map((item) => ({ ...item, date: moment.utc(item.date).format('YYYY-MM-DD') })),
      });
    } catch (e) {
      const message = (e as any)?.response?.data?.message || 'Ошибка при загрузке данных';
      AppToast.show(message);
    }
  };

export const sendDataToAirtable = (): AppThunk => async (dispatch, getState) => {
  dispatch(setIsLoading(true));
  try {
    const data = getState().data.dataToSync;
    await Api.call.post('/schedule/working_time', {
      data,
    });
  } catch (e) {
    const message = (e as any)?.response?.data?.message || 'Ошибка при загрузке данных';
    AppToast.show(message);
  }
  dispatch(setIsLoading(false));
};

export const getAirtableProjects = (): AppThunk<Promise<string[]>> => async () => {
  try {
    const response = await Api.call.get<string[]>('/statistics/projects');
    return response.data.sort((a, b) => (a > b ? 1 : -1));
  } catch (e) {
    const message = (e as any)?.response?.data?.message || 'Ошибка при загрузке данных';
    AppToast.show(message);
  }
  return [];
};

export const getCompanyUsers = () => async (): Promise<CompanyUser[]> => {
  try {
    const response = await Api.call.get<{ data: CompanyUser[] }>('/tg-bot/users');
    return response.data.data;
  } catch (e) {
    return [];
  }
}
