import QueryString from 'query-string';
import axios from 'axios';

const getApiBaseUrl = () => {
  return '/api';
};

const paramsSerializer = (params: Record<string, any>) => {
  return QueryString.stringify(params, {
    arrayFormat: 'bracket',
    skipEmptyString: true,
  });
};

export class Api {
  static setToken(token: string) {
    Api.call.defaults.headers['x-access-token'] = token;
  }

  static call = axios.create({
    paramsSerializer,
    responseType: 'json',
    baseURL: getApiBaseUrl(),
    headers: { 'Content-Type': 'application/json' },
  });
}
