import React, { useCallback, useEffect, useState } from 'react';
import { DropdownPicker } from './DropdownPicker';
import { Grid } from '@mui/material';
import { useAppDispatch } from '../hooks';
import { getAirtableProjects } from '../redux/thunkActions/dataActions';

interface ProjectsPickerProps {
  onSelect: (value: string[]) => void;
  value: string[];
  xs?: number;
}
export function ProjectsPicker(props: ProjectsPickerProps) {
  const [projects, setProjects] = useState<string[]>(props.value);
  const dispatch = useAppDispatch();
  const loadProjects = useCallback(async () => {
    const res = await dispatch(getAirtableProjects());
    setProjects(['', ...res]);
  }, [dispatch]);
  useEffect(() => {
    loadProjects();
  }, [loadProjects]);
  const { xs = 6 } = props;
  return (
    <Grid item xs={xs}>
      <DropdownPicker
        onSelect={props.onSelect}
        multiple
        placeholder='Проект'
        values={projects}
        selected={props.value}
      />
    </Grid>
  );
}
