import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { Loader } from '../components/Loader';
import { useAppDispatch, useAwaitCallback } from '../hooks';
import { SalaryRow, sendSalaryToAirtable, syncSalaryDataAsync } from '../redux/thunkActions/dataActions';
import { MONTHS_NAMES } from '../resources/constants';
import CollapsibleTable, { TableConfig } from '../components/CollapsibleTable';
import { formatNumber } from '../lib/utils/formatNumber';
import { YearPicker } from '../components/YearPicker';

export function SalaryPage() {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<SalaryRow[]>([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  useEffect(() => {
    setData([]);
  }, [selectedYear]);
  const [handleSendToAirtable, isAirtableLoading] = useAwaitCallback(async () => {
    await dispatch(sendSalaryToAirtable(data, selectedYear));
  }, [selectedYear, data, dispatch]);
  const [handleLoadSalary, isGTableLoading] = useAwaitCallback(async () => {
    const res = await dispatch(syncSalaryDataAsync(selectedYear));
    setData(res);
  }, [selectedYear]);
  const byMonth = data.reduce((acc, cur) => {
    const month = cur.date.getMonth();
    if (!acc[month]) {
      acc[month] = [];
    }
    acc[month].push(cur);
    return acc;
  }, new Array<SalaryRow[]>(12));
  const tableConfig: TableConfig = {
    head: [{ label: 'Месяц' }, { label: 'Сумма' }],
    rows: byMonth.map((item, index) => ({
      row: [MONTHS_NAMES[index], formatNumber(item.reduce((acc, cur) => acc + cur.sum, 0))],
      subItems: {
        head: [{ label: 'ФИО' }, { label: 'Сумма' }],
        rows: item.map((i) => ({ row: [i.fullName, formatNumber(i.sum)] })),
      },
    })),
  };
  return (
    <Grid container padding={2} direction={'column'}>
      <Loader isLoading={isGTableLoading || isAirtableLoading} />
      <Grid item container spacing={2} direction={'row'} alignItems={'center'}>
        <YearPicker selected={selectedYear} onChange={setSelectedYear} />
        <Grid item>
          <Button onClick={handleLoadSalary} variant={'contained'}>
            Загрузить
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={handleSendToAirtable} variant={'contained'} color={'error'} disabled={data.length === 0}>
            Сохранить в Airtable
          </Button>
        </Grid>
      </Grid>
      <Grid item paddingY={2}>
        {data.length > 0 && <CollapsibleTable config={tableConfig} />}
      </Grid>
    </Grid>
  );
}
