import React, { PropsWithChildren } from 'react';
import { Footer, Header } from './NavBars';
import { Box, Grid, Toolbar } from '@mui/material';

export function Page(props: PropsWithChildren<any>) {
  return (
    <Box component={'main'} width={'100%'}>
      <Grid container direction={'column'}>
        <Header />
        <Toolbar />
        {props.children}
        <Footer />
        <Toolbar />
      </Grid>
    </Box>
  );
}
