import moment from 'moment';
import { DATE_FORMAT, title } from '../../resources/constants';
import { AirtableFieldsType, AirtableItem, NamedValue } from '../types';

/**
 * Возвращает диапазон дат для выбранного месяца. Нумирация месяцев с 0
 * @param {number} month
 * @param year
 * @returns {[string, string]}
 */
export function getMonthDatesRange(month: number, year: number): [string, string] {
  const monthDate = moment(`1.${month + 1}.${year}`, 'D.M.YYYY');
  const start = moment(monthDate).startOf('M').add(-1, 'd').format(DATE_FORMAT);
  const end = moment(monthDate).endOf('M').add(1, 'd').format(DATE_FORMAT);
  return [start, end];
}

export function recordToArray<T>(data: Record<string, T>): NamedValue<T>[] {
  return Object.keys(data)
    .sort((a, b) => (a > b ? 1 : -1))
    .map((key) => ({ title: key, value: data[key] }));
}

export function groupBy<K extends string | number>(records: AirtableItem[], field: AirtableFieldsType) {
  // @ts-ignore
  const res: Record<K, { records: AirtableItem[] }> = {};
  for (let i = 0; i < records.length; i++) {
    const record = records[i];
    // @ts-ignore
    const value: K = record.fields[field];
    if (!res[value]) {
      res[value] = {
        records: [],
      };
    }
    res[value].records.push(record);
  }
  return res;
}

export function getDayNumber(date: Date) {
  const start = new Date(date.getFullYear(), 0, 0);
  const diff = date.valueOf() - start.valueOf();
  const oneDay = 1000 * 60 * 60 * 24;
  return Math.floor(diff / oneDay);
}

export function orNull(num: number) {
  return isNaN(num) ? 0 : num;
}

export function getTitle() {
  return `${title} - отчеты`;
}
