import { DependencyList, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../lib/types';

export function useMount(mount: () => void | Promise<void> | (() => void), unmount: () => void = () => null) {
  return useEffect(() => {
    const unmountCb = mount();
    return () => {
      unmount();
      if (typeof unmountCb === 'function') {
        unmountCb();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export function useAwaitCallback<T extends (...args: any[]) => Promise<void>>(
  callback: T,
  deps: DependencyList,
): [T, boolean] {
  const [isLoading, setIsLoading] = useState(false);

  const isLoadingRef = useRef(false);
  useEffect(() => {
    isLoadingRef.current = isLoading;
  }, [isLoading]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const _callback = useCallback(
    (async (...args) => {
      if (!isLoadingRef.current) {
        setIsLoading(true);
        await callback(...args);
        setIsLoading(false);
      }
    }) as T,
    deps,
  );
  return [_callback, isLoading];
}

export function useWrappedCallback<T>(callback: ((item: T) => void) | undefined, item: T): () => void {
  return useCallback(() => callback?.(item), [callback, item]);
}

export function useEffectSkipFirst(effect: () => void, deps: any[]) {
  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current) {
      effect();
    }
    isMounted.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export const useIsFocusedState = () => {
  const [isFocused, setIsFocused] = useState(false);
  const onFocus = useCallback(() => {
    setIsFocused(true);
  }, []);
  const onBlur = useCallback(() => {
    setIsFocused(false);
  }, []);
  return { isFocused, onFocus, onBlur };
};

export const useBoolState = (defaultValue = false) => {
  const [state, setState] = useState(defaultValue);
  const setTrue = useCallback(() => setState(true), []);
  const setFalse = useCallback(() => setState(false), []);
  const toggle = useCallback(() => setState(!state), [state]);
  return { state, setState, setTrue, setFalse, toggle };
};

export function useBoolParamCallback(
  callback: (param: boolean) => void,
  deps: any[],
): [(param: boolean) => void, () => void, () => void] {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cb = useCallback(callback, deps);
  const trueCb = useCallback(() => cb(true), [cb]);
  const falseCb = useCallback(() => cb(false), [cb]);
  return [cb, trueCb, falseCb];
}

export function useAppDispatch() {
  return useDispatch<AppDispatch>();
}
