import React, { PropsWithChildren } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { BaseModalProps } from './BaseModalTypes';

export const BaseModal = ({ isOpen, onClose, title, modalActions, children }: PropsWithChildren<BaseModalProps>) => {
  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      {title && <DialogTitle>{title}</DialogTitle>}

      <DialogContent>{children}</DialogContent>

      {modalActions && <DialogActions>{modalActions}</DialogActions>}
    </Dialog>
  );
};
