import React, { useMemo } from 'react';
import { IProjectBody, ProjectData, Role } from '../lib/types';
import { orNull } from '../lib/utils';
import { showMoney } from '../resources/constants';
import { formatNumber } from '../lib/utils/formatNumber';
import CollapsibleTable, { TableConfig } from './CollapsibleTable';

interface Props {
  data: ProjectData[];
  role: Role;
  isAdmin: boolean;
}

function filterBoolean<T>(arr: (T | boolean | undefined | null)[]): T[] {
  // @ts-ignore
  return arr.filter(Boolean);
}

export function PTable(props: Props) {
  const { data } = props;
  const {isAdmin} = props;
  const hideKPI = !showMoney || !isAdmin;

  const sum = data.reduce(
    (sum, cur) => ({
      pnl: sum.pnl + cur.value.pnl,
      totalTime: sum.totalTime + cur.value.totalTime,
      revenue: sum.revenue + cur.value.revenue,
      cost: sum.cost + cur.value.cost,
      kpi: Math.max(sum.kpi, orNull(cur.value.pnl / cur.value.cost) * 100),
    }),
    {
      pnl: 0,
      totalTime: 0,
      revenue: 0,
      cost: 0,
      kpi: 0,
    },
  );
  const tableConfig: TableConfig = useMemo(
    () => ({
      // @ts-ignore
      head: filterBoolean<{ label: string }>([
        { label: 'Название' },
        showMoney && isAdmin && { label: `Себестоимость: ${formatNumber(Math.trunc(sum.cost))}` },
        showMoney && isAdmin && { label: `Доход: ${formatNumber(Math.trunc(sum.revenue))}` },
        showMoney && isAdmin && { label: `PnL: ${formatNumber(Math.trunc(sum.pnl))}` },
        !hideKPI && { label: `ROI` },
        { label: `Часы: ${formatNumber(Math.trunc(sum.totalTime))}` },
      ]),
      rows: data.map((item) => {
        const keys = Object.keys(item.value.detail);
        // @ts-ignore
        const body: IProjectBody = item.value;
        const sorted = keys.sort((a, b) => {
          if (item.value.detail[a]._lineOfBusiness > item.value.detail[b]._lineOfBusiness) {
            return 1;
          }
          return -1;
        });
        const row: (string | number)[] = [item.title];
        if (showMoney && isAdmin) {
          row.push(...[body.cost.toFixed(0), body.revenue.toFixed(0), body.pnl.toFixed(0)].map(formatNumber));
        }
        if (!hideKPI) {
          row.push(`${orNull(body.ROI * 100).toFixed(2)}%`);
        }
        row.push(body.totalTime);
        return {
          row,
          subItems: {
            head: [{ label: 'ФИО' }, { label: 'Часы' }],
            rows: sorted.map((i) => ({ row: [i, body.detail[i].totalTime] })),
          },
        };
      }),
    }),
    [data, hideKPI, isAdmin, sum.cost, sum.pnl, sum.revenue, sum.totalTime],
  );
  return <CollapsibleTable config={tableConfig} />;
}
